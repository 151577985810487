import { BrowserModule, DomSanitizer, Title, HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// tslint:disable-next-line: max-line-length
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthGuard } from '@app/guards/auth.guard';
import { AuthService } from '@app/authentication/auth.service';
import { AuthDeactivateGuard } from '@app/guards/auth-deactivate.guard';
import { ClientLoaderComponent } from '@shared/components/client-loader/client-loader.component';
import { MaterialModule } from '@shared/material.module';
import { HTTPListener, HTTPStatus } from '@app/clientHttp/http.interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SortablejsModule } from 'nxt-sortablejs'
const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  declarations: [
    AppComponent,
    ClientLoaderComponent,
  ],
  imports: [
    // angular
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    // app
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    HammerModule,
    NgxMaskPipe,
    NgxMaskDirective,
    SortablejsModule.forRoot({
            animation: 0
        })
  ],
  providers: [
    provideNgxMask(),
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    AuthGuard,
    AuthService,
    AuthDeactivateGuard,
    Title,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
  ],
  bootstrap: [AppComponent],
  exports: [ClientLoaderComponent]
})
export class AppModule {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'alert',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/alert-2.svg'));
    iconRegistry.addSvgIcon(
      'link',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/link.svg'));
    iconRegistry.addSvgIcon(
      'config',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/config.svg'));
    iconRegistry.addSvgIcon(
      'desktop',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/desktop.svg'));
    iconRegistry.addSvgIcon(
      'mobile',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/mobile.svg'));
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/copy.svg'));
    iconRegistry.addSvgIcon(
      'reload',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/reload.svg'));
    iconRegistry.addSvgIcon(
      'help',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/help.svg'));
    iconRegistry.addSvgIcon(
      'reload',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/reload.svg'));
    iconRegistry.addSvgIcon(
      'plus',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/plus.svg'));
    iconRegistry.addSvgIcon(
      'upArrow',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/upArrow.svg'));
    iconRegistry.addSvgIcon(
      'field',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/field.svg'));
    iconRegistry.addSvgIcon(
      'component',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/component.svg'));
    iconRegistry.addSvgIcon(
      'checked',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/checked.svg'));
    iconRegistry.addSvgIcon(
      'unchecked',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/unchecked.svg'));
    iconRegistry.addSvgIcon(
      'not-conclusive',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/not-conclusive.svg'));
    iconRegistry.addSvgIcon(
      'waiting',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/waiting.svg'));
    iconRegistry.addSvgIcon(
      'person-stroke',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/person-stroke.svg'));
  }
}
