import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { normalizeText } from '@shared/utils/util';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-edit-form-logic',
  templateUrl: './edit-form-logic.html',
  styleUrls: ['./edit-form-logic.scss']
})


export class EditFormLogicComponent implements OnInit {

  public field;

  public actions = [
    { value: 1, name: 'Mostrar este elemento' },
    { value: 2, name: 'Ocultar este elemento' }
  ];

  public conditions = [
    { value: 1, name: 'Se alguma dessas regras corresponder' },
    { value: 2, name: 'Se todas as regras corresponderem' }
  ];
  public operators = [
    { value: 1, name: 'É igual' },
    { value: 2, name: 'É diferente' },
    { value: 3, name: 'É desconhecido' }, // é vazio
    { value: 4, name: 'É conhecido' }, // não é vazio
    { value: 5, name: 'É maior' },
    { value: 6, name: 'É menor' },
    { value: 7, name: 'Contém' },
    { value: 8, name: 'Não contém' }
  ];

  public rules = [];
  public filteredOptions: Observable<any[]>[] = [];
  
  public display = (value: number) => {
    return value ? this.keys.find(field => field.value == value).name : undefined;
  }

  @Input() currentForm;
  @Input() actionsButton;
  @Input() keys;
  @Input() item;
  @Input() info = '';
  @Output() valueChanged = new EventEmitter<any>();
  public disableLogic: UntypedFormControl;
  public condition: UntypedFormControl;
  public action: UntypedFormControl;
  public rulesFields;
  public index;
  public dateFieldMaskMessage;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateAdapter: DateAdapter<any>
  ) {
    this.dateAdapter.setLocale('pt-BR');
    this.rulesFields = this.formBuilder.group({
      formArray: this.formBuilder.array([])
    });
  }

  get formArray() {
    return this.rulesFields.get('formArray') as UntypedFormArray;
  }

  public getForm(i) {
    return this.rulesFields.controls.formArray.controls[i];
  }

  ngOnInit() {
    this.currentForm = this.currentForm.components[0];
    if (this.item && this.item.data && this.item.data.logics) {
      let rules = false;
      rules = this.item.data.logics.map(logic => {
        return {
          id: logic.id,
          key: logic.field_compare_id,
          operator: logic.rule_logic_id,
          value: logic.fixed_value
        };
      });
      const data = this.item.data;
      this.executeChange({
        disableLogic: data.logic_enabled,
        action: data.action_logic_id,
        condition: data.condition_logic_id,
        rules
      });
    }
    if (this.currentForm.conditionalData) {
      this.disableLogic = new UntypedFormControl(this.currentForm.conditionalData.enable);
      this.condition = new UntypedFormControl(this.currentForm.conditionalData.condition, Validators.required);
      this.action = new UntypedFormControl(this.currentForm.conditionalData.action, Validators.required);
    } else {
      this.disableLogic = new UntypedFormControl(false);
      this.condition = new UntypedFormControl('', Validators.required);
      this.action = new UntypedFormControl('', Validators.required);
    }
    if (this.currentForm.conditionalData && this.currentForm.conditionalData.data && this.currentForm.conditionalData.data.length > 0) {
      if (this.item && this.item.data && this.item.data.logics) {
        this.item.data.logics.forEach(i => {
          this.createRule(i);
        });
      }
    }
    if (!this.disableLogic.value) {
      this.condition.disable({ emitEvent: false });
      this.action.disable({ emitEvent: false });
    }
    this.onChanges();
    if (typeof this.actionsButton !== 'undefined') {
      this.actions = this.actionsButton;
    }
  }

  private autoCompleteOptionsFilter(i: number): void {
    this.filteredOptions[i] = this.formArray.at(i).get('key').valueChanges
    .pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map(option => {
        return option ? this._filter(option) : this.keys.slice()
      }
      )
    );
  }

  private _filter(option) {
      if(typeof option == 'number') {
        return this.keys.slice();
      }
      const normalize = normalizeText(option);
      return this.keys.filter(field => normalizeText(field.name).includes(normalize));
  }

  trackByValue(index: number, option: any): number {
    return option.value;
  }

  public checkDateType(val) {
    let isDateType = false;
    this.keys.map((key) => {
      if (val === key.value && key.type === 'date') {
        isDateType = true;
        this.dateFieldMaskMessage  = 'O campo verificado é do tipo DATA o valor deve bater com o formato definido em suas configurações';
      }
    });
    return isDateType;
  }

  public checkSelectBoxesType(val) {
    let isSelectBoxesType = false;
    this.keys.map((key) => {
      if (val === key.value && key.type === 'checkbox') {
        isSelectBoxesType = true;
      }
    });
    return isSelectBoxesType;
  }

  onChanges(): void {
    this.disableLogic.valueChanges.subscribe(val => {
      if (val) {
        this.condition.enable({ emitEvent: false });
        this.action.enable({ emitEvent: false });
        this.rulesFields.controls.formArray.enable({ emitEvent: false });
      } else {
        this.rulesFields.controls.formArray.disable({ emitEvent: false });
        this.condition.disable({ emitEvent: false });
        this.action.disable({ emitEvent: false });
      }
      this.executeChange();
    });
    this.condition.valueChanges.subscribe(val => {
      this.executeChange();
    });
    this.action.valueChanges.subscribe(val => {
      this.executeChange();
    });
    this.formArray.valueChanges.subscribe(val => {
      this.executeLogicsChanges(val);
    })
  }

  

  onRuleChanges(form): void {
    const id = form.controls.id;
    const key = form.controls.key;
    const operator = form.controls.operator;
    const value = form.controls.value;
    if (!this.disableLogic.value) {
      form.disable();
    }
    id.valueChanges.subscribe((val) => {
      this.formArray.controls.filter((item, index) => {
        if (item === id.parent) {
          this.index = index;
        }
      });
      this.rules[this.index].id = val;
      this.executeChange();
    });
    key.valueChanges.subscribe((val) => {
      this.formArray.controls.filter((item, index) => {
        if (item === key.parent) {
          this.index = index;
        }
      });
      this.rules[this.index].key = val;
      this.executeChange();
    });
    operator.valueChanges.subscribe(val => {
      this.formArray.controls.filter((item, index) => {
        if (item === key.parent) {
          this.index = index;
        }
      });
      this.rules[this.index].operator = val;
      this.executeChange();
      form.controls.value.updateValueAndValidity();
    });
    value.valueChanges.subscribe(val => {
      this.formArray.controls.filter((item, index) => {
        if (item === key.parent) {
          this.index = index;
        }
      });
      if (this.checkDateType(this.rules[this.index].key) ) {
        if (val !== null) {
          if (typeof val === 'object' && !isNaN(val.getDate())) {
            val = val.toISOString();
            this.rules[this.index].value = val;
          }
        }
      } else {
        this.rules[this.index].value = val;
      }
      this.executeChange();
    });
  }

  public createRule(i) {
    this.formArray.push(this.createRuleFormGroup(i));
    this.autoCompleteOptionsFilter(this.formArray.length - 1);
    const rule = {
      id: i.id,
      key: i.field_compare_id,
      operator: i.rule_logic_id,
      value: i.fixed_value
    };
    this.rules.push(rule);
    this.onRuleChanges(this.getForm(this.formArray.length - 1));
  }

  public removeRule(i) {
    this.rules.splice(i, 1);
    this.formArray.removeAt(i);
    this.executeChange();
  }

  createRuleFormGroup(i): UntypedFormGroup {
    return this.formBuilder.group({
      id: [i.id],
      key: [i.field_compare_id],
      operator: [i.rule_logic_id],
      value: [i.fixed_value]
    });
  }

  executeLogicsChanges(val) {
    const obj = {
      disableLogic: this.disableLogic.value,
      action: this.action.value,
      condition: this.condition.value,
      rules: val
    };
    this.executeChange(obj);
  }


  emitChange() {
    this.valueChanged.emit(this.currentForm);
  }

  executeChange(preset?) {
    let obj: any;
    if (preset) {
      obj = preset;
    } else {
      obj = {
        disableLogic: this.disableLogic.value,
        action: this.action.value,
        condition: this.condition.value,
        rules: this.rules
      };
    }
    this.handleDisabledLogic(obj.disableLogic);
    this.setLogicData(obj);
    this.emitChange();
  }

  public handleDisabledLogic(val) {
    if (val) {
      this.currentForm.conditional = {
        json: {
          or: null,
          and: null
        }
      };
    } else {
      this.currentForm.conditional = null;
    }
  }

  setLogicData(obj) {
    if (obj.rules.length > 0) {
      this.currentForm.conditionalData = {
        enable: obj.disableLogic,
        action: obj.action,
        condition: obj.condition,
        data: obj.rules
      };
    } else {
      this.currentForm.conditionalData = {
        enable: obj.disableLogic,
        action: obj.action,
        condition: obj.condition,
        data: []
      };
    }

    if (this.currentForm.conditionalData.enable) {
      this.currentForm.logic_enabled = true;
      this.currentForm.action_logic_id = this.currentForm.conditionalData.action;
      this.currentForm.condition_logic_id = this.currentForm.conditionalData.condition;
      this.currentForm.logics = this.currentForm.conditionalData.data.map(logic => {
        return {
          id: logic.id ? logic.id : null,
          field_compare_id: logic.key,
          rule_logic_id: logic.operator,
          fixed_value: logic.value
        };
      });
    } else {
      this.currentForm.logic_enabled = false;
      this.currentForm.action_logic_id = false;
      this.currentForm.condition_logic_id = false;
      this.currentForm.logics = [];
    }
  }
}
