// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    API: '/api',
    BASE_URL: '/',
  
    API5000: '/api',
    API4000: '/api',
    API4500: '/api',
    APINGSERVE: '/api',
    appName: 'processo-seletivo',
    production: true,
  
    storage_path: '/storage/',
  
    imagesPath: 'src/assets/images',

    API_AFYA_IUGU : ' https://vestibularmedicina.afya.com.br/api',
    API_AFYA_IUGU_HML : 'https://processoseletivo.azurewebsites.net/api',
    IUGU_ACCOUNT_0 : 'D2C9C36FBF8B42C78E5F6328881604D9',
    IUGU_ACCOUNT_1 : '3C77EE1A53CE4EEEA5FBC3F809A31F92',
  
    nomeProjeto: 'Rubeus Inscrições e Matrículas',
    logo: 'assets/images/logo/logo-ps.svg',
    logoInstitucional: 'assets/images/logo/logo-rubeus-branca.svg',
    favIcon: 'assets/images/logo/logo-rubeus.svg',
    backgroundClient: 'assets/images/background/background-client.jpg',
    limitMaxUpload: 50,
    defaultDomain: "psgerenciavelteste.apprbs.com.br/",
    intervationDomain: "https://interventionhomolog.apprbs.com.br/"
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  